import React from 'react'
import About from './About'

function Reviews() {
  return (
    <div>
        <About startingPage="Reviews"></About>
    </div>
  )
}

export default Reviews